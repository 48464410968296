import React, { useState } from 'react'
import { Button } from '@material-tailwind/react'
import { format } from 'date-fns'
import { Metadata } from '../lib/models'
import { redeemCode } from '../lib/api'
import styles from '../styles/code-entry.module.css'

export interface CodeEntryProps extends Metadata {
  source: string
  passedCode: string
}

export function formatDate (date: Date | string = ''): string {
  let value = date
  if (value === '') value = new Date()
  try {
    return format(new Date(value), 'LLLL d, y')
  } catch (error) {
    return date as string
  }
}

export default function CodeEntry ({
  source,
  name,
  date,
  description,
  body,
  passedCode = ''
}: Readonly<CodeEntryProps>): JSX.Element {
  const [complete, setComplete] = useState<any>(null)

  let component
  if (complete === null) {
    component = (
      <EnterCode
        source={source}
        body={body ?? null}
        passedCode={passedCode}
        onComplete={setComplete}
      />
    )
  } else {
    switch (complete?.status) {
      case 200:
        component = <DownloadStarted url={complete?.url} />
        break
      case 401:
        component = <UsedCode />
        break
      case 404:
        component = <BadCode />
        break
      default:
        component = <RandomError />
        break
    }
  }

  return (
    <div className={styles.codeEntry}>
      <h1
        data-testid='event_name'
        className='text-3xl m-6 font-bold uppercase'
      >
        {name}
      </h1>
      <p
        data-testid='event_date'
        className='text-gray-500 m-0.5 uppercase'
      >{`${formatDate(date)}`}
      </p>
      <p
        data-testid='event_description'
        className='text-gray-500 m-0.5 uppercase'
      >
        {description}
      </p>
      {component}
    </div>
  )
}

export function EnterCode ({
  source,
  body,
  passedCode,
  onComplete
}: Readonly<{
  source: string
  body: string | null
  passedCode?: string
  onComplete: (result: any) => void
}>): JSX.Element {
  const [code, setCode] = useState<string>(passedCode ?? '')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const checkCode = async (e): Promise<void> => {
    e.preventDefault()
    const value = code.trim()
    if (value.length < 1) {
      setErrorMessage('You must enter your code.')
      return
    }

    try {
      const result = await redeemCode(source, code)
      onComplete(result)
    } catch (error: any) {
      setErrorMessage('An error occurred.')
    }
  }

  return (
    <form onSubmit={e => { void checkCode(e) }}>
      <p data-testid='check-code' className='ml-1 leading-4 my-4'>
        {body}
      </p>
      <p className='ml-1 leading-4 my-4'>
        Add your unique code in the box below and click download to receive your
        file. Each code is only available for one download and will expire after
        one use.
      </p>
      <input
        id='code_entry'
        type='text'
        name='downloadCode'
        value={code}
        onChange={(e) => {
          setCode(e.target.value.toUpperCase())
          setErrorMessage(null)
        }}
        className={styles.codeInput}
        placeholder='ENTER YOUR CODE'
      />
      {/* TODO */}
      {/* <Input
        label="ENTER YOUR CODE"
        labelProps={{
          className: 'text-center uppercase text-gray-500',
        }}
        className="placeholder:text-center placeholder:uppercase placeholder:text-gray-500"
      /> */}
      {errorMessage !== null && (
        <div className='text-red-500 text-center'>{errorMessage}</div>
      )}
      <p className='my-4'>
        <Button
          id='download_button'
          type='submit'
          color='gray'
          className='bg-black'
          nonce={undefined}
          onResize={undefined}
          onResizeCapture={undefined}
        >
          DOWNLOAD NOW
        </Button>
      </p>
      <p className='my-4 leading-4 text-sm'>
        Reach out to{' '}
        <a data-testid='support-email' href='mailto:support@stagepilot.com'>
          support@stagepilot.com
        </a>{' '}
        with any questions, or visit our{' '}
        <a data-testid='faq-link' href='https://stagepilot.com/swag-faq'>
          <strong>FAQs</strong>
        </a>
        .
      </p>
    </form>
  )
}

export function BadCode (): JSX.Element {
  return (
    <div data-testid='error_message'>
      <p data-testid='badcode' className='text-red-500 text-center'>
        Your code doesn’t seem to be working.
      </p>
      <p className='my-4 leading-4 text-sm'>
        Please contact{' '}
        <a href='mailto:support@stagepilot.com'>support@stagepilot.com</a> for
        assistance.
      </p>
    </div>
  )
}

export function UsedCode (): JSX.Element {
  return (
    <div data-testid='error_message'>
      <p data-testid='usedcode' className='text-red-500 text-center'>
        It looks like your code has already been used.
      </p>
      <p className='my-4 leading-4 text-sm'>
        Please contact{' '}
        <a href='mailto:support@stagepilot.com'>support@stagepilot.com</a> for
        assistance.
      </p>
    </div>
  )
}

export function RandomError (): JSX.Element {
  return (
    <div data-testid='error_message'>
      <p data-testid='randomerror' className='text-red-500 text-center'>
        <strong>Something unexpected went wrong.</strong>
      </p>
      <p className='my-4 leading-4 text-sm'>
        Please contact{' '}
        <a href='mailto:support@stagepilot.com'>support@stagepilot.com</a> for
        assistance.
      </p>
    </div>
  )
}

export function DownloadStarted ({ url }: { url: any }): JSX.Element {
  window.location = url
  return (
    <div data-testid='success_message'>
      <p className='ml-1 leading-4 my-4'>
        <strong>Success! Enjoy the track.</strong>
      </p>
      <p className='my-4 leading-4 text-sm'>
        Your download should start automatically. If it does not, please{' '}
        <a href={url}>click here</a>.
      </p>
    </div>
  )
}

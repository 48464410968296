import React, { CSSProperties, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'

import { Metadata } from 'lib/models'
import { decodeUrlData, getMeta } from '../lib/api'

import CodeEntry from '../components/code-entry'
import Footer from '../components/footer'
import styles from '../styles/swagpage.module.css'

/**
 * data can be passed in two ways:
 *
 *   ?k=<key>    a string containing the database key to use when looking up event data
 *  or
 *   ?d=<data>   Base64-encoded data in lieu of a database lookup
 *
 *
 * also, a code can be passed in to pre-fll the form:
 *
 *   ?c=<code>
 */

function useQuery (): URLSearchParams {
  const { search } = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

export async function getMedia (source, data): Promise<Metadata | null> {
  if (source !== null) {
    return await getMeta(source)
  }
  if (data !== null) {
    try {
      return decodeUrlData(data)
    } catch (error) {
      return null
    }
  }
  return null
}

const Home = (): JSX.Element => {
  const [meta, setMeta] = useState<Metadata | null>(null)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [source, setSource] = useState<string | null>(null)

  const query = useQuery()
  const c = query.get('c')
  const s = query.get('s')
  const d = query.get('d')

  useEffect(() => {
    const getMetadata = async (): Promise<void> => {
      try {
        const data = await getMedia(s, d)
        setMeta(data)
      } catch (e) {
        console.log(e)
      } finally {
        setLoaded(true)
      }
    }

    if (!loaded && (s ?? d ?? '').length > 0) {
      void getMetadata()
      setSource(s as string)
    }
  }, [])

  const style: CSSProperties = {}
  let Component
  if (!loaded) {
    Component = <Loading />
  } else if (meta !== null) {
    Component = (
      <CodeEntry source={source ?? ''} {...meta} passedCode={c as string} />
    )
    style.backgroundImage = `url(https://${process.env.ASSETS ?? ''}.s3.amazonaws.com/backgrounds/${meta.backgroundImage ?? 'undefined'})`
  } else {
    Component = <NotFound />
  }
  const whom = meta?.name ?? 'Insider'
  const title = `${whom} Swag, only at StagePilot.com`

  return (
    <div className={styles.background} style={style}>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <title>{title}</title>
        <meta name='description' content={`${whom} Swag on StagePilot.com`} />
        <link rel='icon' href='/favicon.ico' />
      </Helmet>
      <div className={styles.main}>{Component}</div>
      <Footer />
    </div>
  )
}

export function Loading (): JSX.Element {
  return (
    <div className='panel'>
      <h1 className='text-3xl m-6 font-bold uppercase'>Loading</h1>
      <p className='ml-1 leading-4 my-4'>Checking for your content...</p>
    </div>
  )
}

export function NotFound (): JSX.Element {
  return (
    <div className='panel'>
      <h1 className='text-3xl m-6 font-bold uppercase'>Not found.</h1>
      <p className='ml-1 leading-4 my-4'>
        The requested resources was not found.
      </p>
    </div>
  )
}

export default Home

import { decode } from 'js-base64'
import { Metadata } from './models'

export const BASE_URL: string = {
  prod: 'https://api.swag.stagepilot.net',
  qa: 'https://api.swag.stagepilot.xyz',
  dev: 'https://api.swag.stagepilot.dev',
  local: 'https://api.swag.stagepilot.dev'
}[process.env.STAGE ?? 'dev']

/**
 * lookup an item by "source"
 * @param sourceId (string) the source to look up
 * @returns (Metadata) the event data to fill the page
 */
export async function getMeta (sourceId: string): Promise<any> {
  const response = await fetch(`${BASE_URL}/item/${sourceId}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const json = await response.json()
  return { ...json, status: response.status }
}

/**
 * lookup all source items
 * @returns (Metadata) Array the array of events in db
 */
export async function getSources (): Promise<any> {
  const response = await fetch(`${BASE_URL}/items`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': localStorage.getItem('apikey')
    }
  })
  const json = await response.json()

  if (!response.ok) {
    throw new Error(JSON.stringify(response))
  }
  return json
}

interface WriteMetaResult extends Metadata {
  url?: string
  backgroundUrl?: string
  headerUrl?: string
}

/**
 *
 * @param meta (Metadata) the data to write
 * @returns (WriteMetaResult) The written metadata, along with presigned URLs
 */
export async function writeMeta (meta: Metadata): Promise<WriteMetaResult> {
  const response = await fetch(`${BASE_URL}/item/${meta.source_id}`, {
    method: 'put',
    body: JSON.stringify(meta),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': localStorage.getItem('apikey')
    }
  })
  if (!response.ok) {
    throw new Error(JSON.stringify(response))
  }
  const json = await response.json()
  return json
}

export async function getMedia (sourceId: string): Promise<any> {
  const response = await fetch(`${BASE_URL}/download/`, {
    method: 'post',
    body: JSON.stringify({ source_id: sourceId }),
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': localStorage.getItem('apikey')
    }
  })
  if (!response.ok) {
    throw new Error(JSON.stringify(response))
  }
  const json = await response.json()
  return json
}

/**
 * decode Metadata from base64-encoded data
 * @param data (string) base64-encoded Metadata object
 * @returns (Metadata) the event data to fill the page
 */
export function decodeUrlData (data: any): Metadata {
  return JSON.parse(decode(data)) as Metadata
}

/**
 * redeem a download code
 * @param sourceId (string) the source for which to redeem a code
 * @param code (string) the download code to redeem
 */
export async function redeemCode (
  sourceId: string,
  code: string
): Promise<any> {
  const response = await fetch(`${BASE_URL}/item/${sourceId}`, {
    method: 'post',
    body: JSON.stringify({ code }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
  const json = await response.json()
  return { ...json, status: response.status }
}

/**
 * upload a file
 */
export async function upload (url, file): Promise<any> {
  const response = await fetch(url, {
    method: 'post',
    body: file.data
  })
  const json = await response.json()
  return { ...json, status: response.status }
}

/**
 * Invite fans to download (does not send invitations)
 * @param source_id (string) the source id attached to the event for which fans will be invited
 * @param fans an array of fans to write to the dynamodb table for invitations
 */
export async function inviteFans (sourceId: string, fans: any[]): Promise<any> {
  const response = await fetch(`${BASE_URL}/invite/${sourceId}`, {
    method: 'post',
    body: JSON.stringify({ fans }),
    headers: {
      'x-api-key': localStorage.getItem('apikey') ?? ''
    }
  })
  const json = await response.json()
  if (!response.ok) {
    throw new Error(JSON.stringify(response))
  }
  return json
}

/**
 * Gets fans that have been invited
 * @param source_id (string) the source id attached to the event invitations are attached to
 */
export async function getInvitations (sourceId: string): Promise<any> {
  const response = await fetch(`${BASE_URL}/invitations/${sourceId}`, {
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': localStorage.getItem('apikey')
    }
  })

  const json = await response.json()

  if (!response.ok) {
    throw new Error(JSON.stringify(response))
  }
  return json
}

/**
 * Login
 * @param username (string)
 * @param password (string)
 */
export async function login (username: string, password: string): Promise<any> {
  const response = await fetch(`${BASE_URL}/login`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ username, password })
  })
  if (!response.ok) {
    throw new Error(JSON.stringify(response))
  }
  const json = await response.json()
  return json
}

import React from 'react'
import styles from '../styles/footer.module.css'

export default function Footer (props): JSX.Element {
  const d = new Date()
  const year = d.getFullYear().toString()
  return (
    <footer className={styles.footer}>
      <div className={styles.logo}>
        <img width='96' loading='lazy' alt='StagePilot Logo' src='/images/SP-KO-01.png' />
      </div>
      <div className={styles.links}>
        <div>&copy;{year} StagePilot, LLC.</div>
        <a
          data-test-id='footer_privacy_policy_link'
          href='https://stagepilot.com/legal/privacy'
        >
          Privacy Policy
        </a>
        <a
          data-test-id='footer_terms_conditions_link'
          href='https://stagepilot.com/legal/terms-of-use'
        >
          Terms &amp; Conditions
        </a>
        <a
          data-test-id='footer_contacts_us_link'
          href='mailto:hello@stagepilot.com'
        >
          Contact Us
        </a>
      </div>
    </footer>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xtAdmH0T5qjX5dPg8iUE{align-items:center;align-content:center;background-color:#2b2e30;color:#fff;display:flex;flex-direction:row;font-size:13px;text-transform:uppercase}.xtAdmH0T5qjX5dPg8iUE>div{margin:1em}.xtAdmH0T5qjX5dPg8iUE a{color:#fff;text-decoration:none}.bJi9ipRTmG4Op5eX5Kzx{margin:.2em}.Cm1lD_44YA5ghG8rUTcW{display:flex;flex-direction:column}.Cm1lD_44YA5ghG8rUTcW>*{margin:.4em 1em}@media screen and (min-width: 640px){.Cm1lD_44YA5ghG8rUTcW{flex-direction:row}}`, "",{"version":3,"sources":["webpack://./src/styles/footer.module.css"],"names":[],"mappings":"AAAA,sBACE,kBAAA,CACA,oBAAA,CACA,wBAAA,CACA,UAAA,CACA,YAAA,CACA,kBAAA,CACA,cAAA,CACA,wBAAA,CAGF,0BACE,UAAA,CAGF,wBACE,UAAA,CACA,oBAAA,CAGF,sBACE,WAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CAGF,wBACE,eAAA,CAGF,qCACE,sBACE,kBAAA,CAAA","sourcesContent":[".footer {\n  align-items: center;\n  align-content: center;\n  background-color: #2b2e30;\n  color: #fff;\n  display: flex;\n  flex-direction: row;\n  font-size: 13px;\n  text-transform: uppercase;\n}\n\n.footer > div {\n  margin: 1em;\n}\n\n.footer a {\n  color: #fff;\n  text-decoration: none;\n}\n\n.logo {\n  margin: 0.2em;\n}\n\n.links {\n  display: flex;\n  flex-direction: column;\n}\n\n.links > * {\n  margin: 0.4em 1em;\n}\n\n@media screen and (min-width: 640px) {\n  .links {\n    flex-direction: row;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export var footer = `xtAdmH0T5qjX5dPg8iUE`;
export var logo = `bJi9ipRTmG4Op5eX5Kzx`;
export var links = `Cm1lD_44YA5ghG8rUTcW`;
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Home from './index'
import { ThemeProvider } from '@material-tailwind/react'
import '../styles/globals.css'

const gitVersion = (process.env.GIT_VERSION ?? '')
if (gitVersion.length > 0) {
  console.log(`Swag version: '${gitVersion}'`)
}

const rootEl = document.getElementById('root')
if (rootEl === null) throw new Error('Application misconfigured.')

const root = ReactDOM.createRoot(rootEl)

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <BrowserRouter>
        <Home />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
)
